import Vue from 'vue/dist/vue.js';
import { Carousel, Slide } from 'vue-carousel';

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#pagesHome',
    components: {
        Carousel,
        Slide
    },
    data: {
      
    },
    methods: {
      
    },
  })
})
